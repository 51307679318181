// General
import classNames from 'classnames';
import { graphql, navigate, PageProps } from 'gatsby';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI-kit
import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { clearAssistOthersFormValues } from 'state/account/account.reducers';
import { accountResendVerificationEmailRoutine } from 'state/account/account.routines';
import {
    accountIsSendingVerificationLink,
    accountRegistrationFormValuesSelector,
    accountSignInUsernameSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { joinMembershipPlanInviteListSelector } from 'state/join-membership-plan/join-membership-plan.selectors';
import { initialState, setMembershipFormValues } from 'state/membership-registration/membership-registration.reducer';
// States
import { membershipRegistrationFormValuesSelector } from 'state/membership-registration/membership-registration.selectors';

import { EMAIL_POLL_TIME_BETWEEN_REQUESTS_SECONDS } from 'const/account';

import { getPhoneNumber } from 'util/globalVariables';
import storageHelper from 'util/storageHelper';

// Hooks
import useMembershipRegistrationInit from 'hooks/useMembershipRegistrationInit';

// Styles
import './confirm-email.style.scss';

// Main component
const SuccessPage = ({ location, data }: PageProps<GatsbyTypes.InsuredDataQuery>) => {
    // General
    const params = new URLSearchParams(location.search);
    const flowParameter = params.get('flow');
    const typeParameter = params.get('type');

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { onInitInsured, onInit } = useMembershipRegistrationInit();

    // States
    const [email, setEmail] = useState<string | undefined>(storageHelper.local.getEmailConfirmation() || undefined);
    const [countdown, setCountdown] = useState<number>(parseInt(storageHelper.local.getResendCountdown() || '0', 10));
    const [canResend, setCanResend] = useState(countdown === 0);

    // Selectors
    const formValues = useSelector(membershipRegistrationFormValuesSelector);
    const inviteList = useSelector(joinMembershipPlanInviteListSelector);
    const signInUsername = useSelector(accountSignInUsernameSelector);
    const assistOthersFormValues = useSelector(accountRegistrationFormValuesSelector);

    const isSendingVerificationLink = useSelector(accountIsSendingVerificationLink);

    const isButtonHome =
        flowParameter === 'birdi-care' ||
        flowParameter === 'mail-order' ||
        flowParameter === 'assist-others' ||
        flowParameter === 'insured-validation';

    const eyebrowText =
        flowParameter === 'birdi-care'
            ? t('membership.success.weAreReady')
            : flowParameter === 'insured-validation'
            ? t('membership.success.joinBirdi')
            : t('membership.success.oneLastStep');

    const linkStyle = classNames('no-margin', { 'disabled-button': !canResend });
    useEffect(() => {
        // Check if there's an existing countdown value on page load
        const storedCountdown = storageHelper.local.getResendCountdown();
        if (storedCountdown) {
            const countdownValue = parseInt(storedCountdown, 10);
            setCountdown(countdownValue);
            setCanResend(countdownValue === 0);
        }
    }, []);
    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown((prev) => {
                    const updatedCountdown = prev - 1;
                    storageHelper.local.setResendCountdown(updatedCountdown);
                    if (updatedCountdown <= 0) {
                        setCanResend(true);
                        clearInterval(timer);
                        storageHelper.local.removeResendCountdown();
                    }
                    return updatedCountdown;
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [countdown]);

    useEffect(() => {
        let emailAddress: string | undefined;

        switch (flowParameter) {
            case 'birdi-care':
                emailAddress =
                    typeParameter === 'join-membership-plan' ? inviteList[0]?.recipientEmailAddress : formValues.email;
                break;
            case 'mail-order':
                emailAddress = formValues.username;
                break;
            case 'unverified-email':
                emailAddress = signInUsername;
                break;
            case 'assist-others':
                emailAddress = assistOthersFormValues?.username;
                break;
            default:
                break;
        }

        if (emailAddress) {
            setEmail(emailAddress);
            storageHelper.local.setEmailConfirmation(emailAddress);
        }
        if (formValues.email) dispatch(setMembershipFormValues(initialState.formValues));
        if (assistOthersFormValues?.username) dispatch(clearAssistOthersFormValues());
    }, [
        flowParameter,
        inviteList,
        formValues,
        typeParameter,
        signInUsername,
        assistOthersFormValues?.username,
        dispatch
    ]);

    const handleResendLink = useCallback(() => {
        if (!canResend) return;
        dispatch(
            accountResendVerificationEmailRoutine.trigger({
                email: email,
                onSuccess: () => {
                    setCanResend(false);
                    setCountdown(EMAIL_POLL_TIME_BETWEEN_REQUESTS_SECONDS);
                    storageHelper.local.setResendCountdown(EMAIL_POLL_TIME_BETWEEN_REQUESTS_SECONDS);
                    dispatch(
                        openModal({
                            showClose: false,
                            size: 'xl',
                            isModalCentered: true,
                            onClose: () => {
                                dispatch(closeModal({}));
                            },
                            bodyContent: (
                                <BirdiModalContent
                                    icon={'default'}
                                    title={t('modals.updateProfile.title')}
                                    body={t('pages.confirmEmail.resendLink.success.message')}
                                />
                            ),
                            ctas: [
                                {
                                    dataGALocation: 'ResendVerificationLinkSuccess',
                                    label: t('registration.errors.modals.default.button'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: false,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('registration.errors.modals.default.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: (
                                <BirdiModalContentAlt
                                    subTitle={t('pages.confirmEmail.resendLink.error.message')}
                                    note={t('registration.errors.modals.default.note', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    dataGALocation: 'ResendVerificationLinkError',
                                    label: t('registration.errors.modals.default.button'),
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    variant: 'primary'
                                }
                            ]
                        })
                    );
                }
            })
        );
    }, [dispatch, email, t, canResend]);

    const commonMessage = () => {
        return (
            <ToastBox variant="info" icon="info">
                <p className="note">
                    <Trans
                        i18nKey="pages.confirmEmail.footNote"
                        values={{
                            phoneNumber: getPhoneNumber({})
                        }}
                    />
                </p>
            </ToastBox>
        );
    };

    const renderContent = (flow: string | null) => {
        switch (flow) {
            case 'birdi-care':
                return (
                    <>
                        <p className="subtitle1">
                            <Trans i18nKey="pages.confirmEmail.flow.birdiCare" values={{ email }} />
                        </p>
                        {commonMessage()}
                    </>
                );
            case 'unverified-email':
                return (
                    <>
                        <p className="subtitle1">{t('pages.confirmEmail.flow.unverifiedEmail')}</p>
                        {commonMessage()}
                    </>
                );
            case 'mail-order':
                return (
                    <>
                        <p className="subtitle1">
                            <Trans i18nKey="pages.confirmEmail.flow.mailOrder" values={{ email }} />
                        </p>
                        {commonMessage()}
                    </>
                );
            case 'assist-others':
                return (
                    <>
                        <p className="subtitle1">
                            <Trans i18nKey="pages.confirmEmail.flow.mailOrder" values={{ email }} />
                        </p>
                        {commonMessage()}
                    </>
                );
            case 'insured-validation':
                return (
                    <>
                        <p className="subtitle1">
                            <Trans i18nKey="pages.confirmEmail.flow.insuredValidation" />
                        </p>
                    </>
                );
            default:
                return (
                    <p className="subtitle1">
                        <Trans i18nKey="pages.linkExpired.subtitle" />
                    </p>
                );
        }
    };

    useEffect(() => {
        const redirectTo = () => {
            // Case 1: If no flow parameter exists and both initialization functions fail, redirect to `/get-started`.
            if (!flowParameter && !onInit() && !onInitInsured()) return '/get-started';

            // Case 2: If no email is stored, redirect to the home page `/`.
            if (email === null) return '/';

            // Default: No redirection required.
            return null;
        };

        // Determine the redirection path and navigate if a path is returned.
        const path = redirectTo();
        if (path) navigate(path);
    }, [email, flowParameter, onInit, onInitInsured]);

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('membership.success.profileTitle') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                currentFlow={'membership-registration-header'}
                eyebrowText={eyebrowText}
                title={
                    flowParameter === 'insured-validation'
                        ? t('pages.confirmEmail.flow.requestSent')
                        : t('membership.success.validateYourEmail')
                }
            >
                <Container className="confirm-email">
                    {renderContent(flowParameter)}

                    <Link
                        to={isButtonHome ? '/' : '/sign-in'}
                        label={
                            isButtonHome
                                ? t('pages.confirmEmail.btnLabel.goHomepage')
                                : t('pages.confirmEmail.btnLabel.returnSignIn')
                        }
                        variant="nav-button-primary"
                        dataGALocation="membership-registration-go-homepage"
                        ariaLabel="go-homepage"
                    />
                </Container>
            </WorkflowLayoutFormWrapper>

            {flowParameter !== 'insured-validation' && (
                <div className="resend-link-section">
                    {canResend && <p>{t('pages.confirmEmail.resendLink.description')}</p>}
                    <Button
                        async
                        label={
                            canResend
                                ? t('pages.confirmEmail.resendLink.btnLabel')
                                : t('pages.confirmEmail.resendLink.countdownLabel', {
                                      countdown: countdown
                                  })
                        }
                        type="button"
                        variant="text-blue"
                        className={linkStyle}
                        onClick={handleResendLink}
                        disabled={!canResend || isSendingVerificationLink}
                        isBusy={isSendingVerificationLink}
                    />
                </div>
            )}
        </WorkflowLayout>
    );
};

export default SuccessPage;

export const query = graphql`
    query SuccessData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
